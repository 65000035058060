import React, {useState, useEffect} from "react";
import app from "../../firebase";

export const AuthContext = React.createContext();
const db = app.firestore()
export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null)
	const [userInfo, setUserInfo] = useState({})
	// const readSession = () => {
	// 	const user = window.sessionStorage.getItem(
	// 		`firebase:authUser:${process.env.REACT_APP_API_KEY}:[DEFAULT]`
	// 	);
	// 	if(user) setLogedIn(true)
	// }
	useEffect(() => {
		// readSession()
		app.auth().onAuthStateChanged(setCurrentUser);
	}, [])
	return(
		<AuthContext.Provider value={{
			currentUser,
			userInfo,
			setUserInfo
		}}>
			{children}
		</AuthContext.Provider>
	)
}
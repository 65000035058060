import React, {useState, useEffect, useContext} from "react";
import ReactPlayer from 'react-player';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import LessonsList from "../components/course_info/LessonsList";
import {useHistory, useParams, Route, useLocation} from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import {AuthContext} from "../components/auth/AuthProvider";

import app from "../firebase";
const db = app.firestore()

const useStyles = makeStyles((theme)=>({
    root:{
        flexGrow:1,
        marginLeft: 20,
        marginRight: 30,
        marginTop:20
    },
    mediaContainer:{
        height: 250
    },
    courseContent:{
        height:400
    },
    headerContent:{
        margin: theme.spacing(3,4),
    },
    enrolButton:{
        alignSelf:"flex-start",
        marginTop: 10
    },
    progressContainer: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    }
}))
const mediaControls = {
    width: "100%",
    height: "100%",
    playing: true
}
const CourseInfo = () => {
    const classes = useStyles()
    const history = useHistory()
    const { courseId } = useParams()
    const location = useLocation()
    const { data } = location.state || {data:{}}
    const [loading, setLoading] = useState(false)
    const [courseContent, setCourseContent] = useState([])
    const {currentUser, userInfo, setUserInfo} = useContext(AuthContext)
    const [courseStartLoading, setCourseStartLoading] = useState(false)
    
    const handleCourseStart = async () => {
        setCourseStartLoading(true)
        const isNewUser = userInfo.subscribed_courses ? false : true
        if(!isNewUser ? userInfo.subscribed_courses.includes(courseId): false){
            history.push(`/course-content/${courseId}`)
        } else {
            await db.collection("users").doc(currentUser.uid)
            .update({
                courses_track: isNewUser ?
                {
                  [courseId]: {
                    finished_lessons: [],
                    finished_components: [],
                    pointer:{
                        lesson: "",
                        component:""
                    }
                  }  
                } :
                {
                    ...userInfo.courses_track,
                    [courseId]: {
                        finished_lessons: [],
                        finished_components: [],
                        pointer:{
                            lesson: "",
                            component:""
                        }
                    } 
                },
                subscribed_courses: isNewUser ?
                [courseId] :
                [
                    ...userInfo.subscribed_courses,
                    courseId 
                ]
            }).then(() => {
                setUserInfo(prevState => ({
                    ...prevState,
                    subscribed_courses: isNewUser ?
                    [courseId] :
                    [
                        ...userInfo.subscribed_courses,
                        courseId 
                    ],
                    courses_track: isNewUser ?
                    {
                        [courseId]: {
                            finished_lessons: [],
                            finished_components: [],
                            pointer:{
                                lesson: "",
                                component:""
                            }
                        }  
                    } :
                    {
                        ...userInfo.courses_track,
                        [courseId]: {
                            finished_lessons: [],
                            finished_components: [],
                            pointer:{
                                lesson: "",
                                component:""
                            }
                        } 
                    }
                }))
                history.push(`/course-content/${courseId}`)
            })
        }
    }
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            await db.collection("courses").doc(courseId)
            .collection("lessons").orderBy("number", "asc").get()
            .then(snapshot => {
                let lessons = []
                snapshot.forEach(lesson => {
                    lessons.push({...lesson.data(), id: lesson.id })
                })
                setCourseContent(lessons)
                setLoading(false)
            }).catch(err=>console.log(`Error: ${err}`))

            await db.collection("users").doc(currentUser.uid).get()
            .then(doc => {
                setUserInfo({...doc.data()})
            }).catch(err => `Error: ${err}`)
        }
        fetchData()
    },[])
    
    return(
        <React.Fragment>
        <Divider variant="middle"/>
        <Grid container className = { classes.root } >
            <Grid className={classes.headerContent} xs={12} container item spacing={3}>
                <Grid item xs={1}></Grid>
                <Grid container justify="flex-start" xs={6} item>
                    <Typography align="left" variant="h3">{data.title}</Typography>
                    <Typography align="left" variant="subtitle1">
                        {data.description}
                    </Typography>
                    <Button 
                        className={classes.enrolButton} 
                        color="secondary" 
                        variant="contained"
                        onClick={handleCourseStart}
                        disabled={courseContent.length === 0}
                    >
                        {   
                            courseContent.length > 0 ?
                            userInfo.subscribed_courses?
                            userInfo.subscribed_courses.includes(courseId) ?
                            "Davom etirish":
                            "Kursni boshlash":
                            "Kursni boshlash":
                            "Kurs darslari hali yuklanmagan"
                            
                        }
                    </Button>
                </Grid>
                <Grid className={classes.mediaContainer} xs={4} item>
                    <ReactPlayer {...mediaControls} url={data.video}/>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
            <Grid container className={classes.LessonsList}>
                {
                    loading ? 
                    <div className={classes.progressContainer}>
                        <LinearProgress />
                    </div>:
                    <div></div>
                }
                {
                    loading ?
                    <div></div>:
                    courseContent.length > 0 ?
                    <React.Fragment>
                        <Grid item  xs={1}></Grid>
                        <Grid item xs={7}>
                            <LessonsList courseContent={courseContent}/>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </React.Fragment>:
                    <div></div>
                }
            </Grid>
        </Grid>
        </React.Fragment>
    )
}

export default CourseInfo
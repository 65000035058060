import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./components/auth/AuthProvider";
import {BrowserRouter as Router} from "react-router-dom";

import {theme} from "./theme"
import {ThemeProvider} from "@material-ui/core/styles"

import app from "./firebase";

ReactDOM.render(
  <React.StrictMode>
  	<AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
  	</AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useContext} from "react";
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AuthContext} from "../auth/AuthProvider";

const ContentTable = ({orderedLessons, fullContent, courseId, pointer}) => {
    const {userInfo} = useContext(AuthContext)
    return(
        <React.Fragment>
             <Stepper orientation="vertical">
                 {
                    orderedLessons.map(lesson => {
                        return(
                            <Step
                                key={lesson.id} 
                                active = {
                                    userInfo.courses_track[courseId]
                                    .finished_lessons
                                    .includes(lesson.id)
                                }
                            >
                                <StepLabel>
                                    <ExpansionPanel defaultExpanded={pointer.lesson===lesson.id}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                        <Typography color="secondary" variant="h6">{lesson.title}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Stepper orientation="vertical">
                                                {   
                                                    fullContent[lesson.id]?
                                                    fullContent[lesson.id].components
                                                    .map(component=>{
                                                        return(
                                                            <Step 
                                                                key={component.id}
                                                                active={
                                                                    userInfo.courses_track[courseId]
                                                                    .finished_components
                                                                    .includes(component.id)
                                                                }
                                                            >
                                                                <StepLabel>
                                                                    {
                                                                        component.type === "v" ? 
                                                                        `Video${component.title?`| ${component.title}`:""}`:
                                                                        `Test${component.title?`| ${component.title}`:""}`
                                                                    }
                                                                </StepLabel>
                                                            </Step>
                                                        )
                                                    }):
                                                    <div>
                                                        <Typography>Dars materiallari hali joylashtirilmagan</Typography>
                                                    </div>
                                                }
                                            </Stepper>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </StepLabel>
                            </Step>
                        )
                    })
                 }
                </Stepper> 
        </React.Fragment>
    )
}
export default ContentTable
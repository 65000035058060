import React, {useContext} from "react";
import logoImage from "../assets/logo_full.svg";
import { AuthContext } from "../components/auth/AuthProvider";
import app from "../firebase";

import {useHistory} from "react-router-dom"

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import About from "../pages/About";

const useStyles = makeStyles((theme)=>({
    root:{
        marginTop:10,
        flexGrow:1,
        marginBottom: 15,
        height: "80"
    },
    logoContainer:{
        justifyContent:"center"
    },
    button: {
        margin: 5
    }
}))


const Header = () => {
    const classes = useStyles()
    const { currentUser } = useContext(AuthContext);
    const history = useHistory()

    const onSignout = () => {
        app.auth().signOut().then(function() {
            console.log("SignedOut")
            // Sign-out successful.
          }).catch(function(error) {
            // An error happened.
            console.log(error)
        });
    }
    const handleInAndOut = () => {
        if(currentUser){
            onSignout()
        } else {
            history.push("/login")
        }
    }
    return(
        <Grid className={classes.root} container>
            <Grid container item xs={4}></Grid>
            <Grid onClick={()=>history.push("/")} container className={classes.logoContainer} item xs={4} justify="center">
                <img style={{margin:5}} src={logoImage}/>
            </Grid>
            <Grid item xs={4} container justify="flex-end" alignItems="center">
                <Grid item>
                    <Button onClick={()=>history.push("/about")} className={classes.button} color="primary" variant="contained">
                        <Typography variant="button" color="secondary">
                            Loyiha haqida
                        </Typography>
                    </Button>
                    <Button onClick={handleInAndOut} className={classes.button} color="primary" variant="contained">
                        <Typography variant="button" color="secondary">
                            {currentUser ? "Chiqish" : "Kirish"}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Header
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark:"#b28d1c",
      main:"#FFCA28",
      light:"#ffd453"
    },
    secondary: {
      dark:"#115d56",
      main:"#19857b",
      light:"#479d95"
    },
  },
  status: {
    danger: 'orange',
  },
});

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import { Typography, Stepper, StepLabel, StepContent, Step } from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
    root:{
        flexGrow:1
    }
}))

const LessonsList = ({courseContent}) => {
    const classes = useStyles()
    return(
        <React.Fragment>
            <Divider/>
            <Grid container item xs={12}>
                <Grid xs={12} item>
                    <Typography align="left" variant="h4">
                        Kurs Darslari
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Stepper orientation = "vertical">
                        {
                            courseContent.map(lesson=>{
                                return(
                                    <Step key={lesson.id} active = {true}>
                                        <StepLabel>
                                            <Typography variant="h6" color="secondary" align="left">
                                                {lesson.title}
                                            </Typography>
                                        </StepLabel>
                                        <StepContent>
                                            <Typography align="left">
                                                {lesson.description}
                                            </Typography>
                                        </StepContent>
                                    </Step>
                                )
                            })
                        }
                    </Stepper> 
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LessonsList
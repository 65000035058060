import React from 'react';
import './App.css';
import Login from "./pages/Login";
import {Route, Switch} from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Divider from "@material-ui/core/Divider";
import CourseInfo from "./pages/CourseInfo";
import CourseContent from "./pages/CourseContent";
import PrivateRoute from "./components/auth/PrivateRoute";
import About from "./pages/About";

function App() {
  return (
    <React.Fragment>
      <Header/>
      <div>
        <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/about" component={About}/>
          <PrivateRoute path="/course-info/:courseId" component={CourseInfo}/>
          <PrivateRoute path="/course-content/:courseId" component={CourseContent}/>
          <Route exact path="/" component={Home}/>
        </Switch>
      </div>
      <Footer/>
    </React.Fragment>
  );
}

export default App;

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import {useHistory, withRouter} from "react-router-dom";

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow:1
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 60,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.7)',
        height:"40%"
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
            paddingRight: 0,
        },
        height: 200
    },
}))

const CourseItem  = ({data}) => {
    const classes = useStyles()
    const history = useHistory()
    return(
        <Paper elevation={20} className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${data.cover})` }}>
            <CardActionArea 
                onClick={
                    () => history.push({
                        pathname: `/course-info/${data.id}`,
                        state:{
                            data: data
                        }
                    })
                } 
                component="a">
            {/* Increase the priority of the hero background image */}
            {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
            <div className={classes.overlay} />
            <Grid container item justify="flex-end" alignItems="flex-end" className={classes.root}>
                <Grid style={{marginTop:60}}  container item justify="center" alignItems="center">
                <div className={classes.mainFeaturedPostContent}>
                    <Typography align="center" color="primary" component="h5" variant="h4">
                        {data.title}
                    </Typography>
                </div>
                </Grid>
            </Grid>
        </CardActionArea>
        </Paper>
    )
}

export default withRouter(CourseItem)
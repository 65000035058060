import React, { useState, useEffect, useContext } from "react";
import ContentTable from "../components/course_content/ContentTable";
import MainArea from "../components/course_content/MainArea";
import {useParams} from "react-router-dom";
import {
    Grid,
    Hidden
} from "@material-ui/core";
import {AuthContext} from "../components/auth/AuthProvider"
import {makeStyles} from "@material-ui/core/styles";
import app from "../firebase";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography"
import NavButtons from "../components/course_content/NavButtons";

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop: 30,
    },
    progressContainer: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    }
}))
const db = app.firestore()

const CourseContent = () => {
    const classes = useStyles()
    const { courseId } = useParams()
    const {currentUser, userInfo, setUserInfo} = useContext(AuthContext)
    const [fullContent, setFullContent] = useState({})
    const [orderedLessons, setOrderedLessons] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentPosition, setCurrentPosition] = useState({})
    const [contentError, setContentError] = useState(null)
    
    const handleNextNavigation = () => {
        let currentLessonIndex
        const currentLessonComponents = fullContent[currentPosition.lesson].components
        let currentComponentIndex
        
        let indexLesson = 0
        let indexComponent = 0
        
        orderedLessons.forEach(lesson=>{
            if(lesson.id === currentPosition.lesson){
                currentLessonIndex = indexLesson
            }
            indexLesson = indexLesson + 1
        })
        currentLessonComponents.forEach(component=>{
            if(component.id === currentPosition.component){
                currentComponentIndex = indexComponent
            }
            indexComponent = indexComponent + 1
        })
        const combinerIfDoesntInclude = (list, item) => {
            let contains = list.includes(item)
            if(contains){
                return [...list]
            } else {
                return [...list, item]
            }
            
        }
        
        if(currentLessonComponents[currentComponentIndex + 1]){
            const finished_components = combinerIfDoesntInclude(
                userInfo.courses_track[courseId].finished_components,
                currentPosition.component
            )
            //If there is next component of the course HERE YOU ARE
            db.collection("users").doc(currentUser.uid).
            update({
                [`courses_track.${courseId}.finished_components`]:finished_components,
                [`courses_track.${courseId}.pointer.component`]:currentLessonComponents[currentComponentIndex + 1].id
            }).catch(err=>{alert(err);console.log(err)})
            
            setUserInfo(prevState => ({
                ...prevState,
                courses_track: {
                    ...prevState.courses_track,
                    [courseId]: {
                        ...prevState.courses_track[courseId],
                        finished_components:finished_components,
                        pointer:{
                            ...prevState.courses_track[courseId].pointer,
                            component: currentLessonComponents[currentComponentIndex + 1].id
                        }
                    }
                }
            }))
            
            setCurrentPosition(prevState => ({
                ...prevState,
                component: currentLessonComponents[currentComponentIndex + 1].id
            })
            )
        } else {
            // There is no more component left, Checking the next lesson
            if(orderedLessons[currentLessonIndex+1]){
                //Checking the next lesson
                if(!fullContent[orderedLessons[currentLessonIndex+1].id]){
                    setContentError("Bu darslik materiallari hali joylashtirilmagan")
                } else {
                    //Next lesson has the component to display HERE YOU ARE
                    const finished_lessons = combinerIfDoesntInclude(
                        userInfo.courses_track[courseId].finished_lessons,
                        currentPosition.lesson
                    )
                    db.collection("users").doc(currentUser.uid).
                    update({
                        [`courses_track.${courseId}.pointer`]: {
                            lesson: orderedLessons[currentLessonIndex+1].id,
                            component: fullContent[orderedLessons[currentLessonIndex+1].id]
                            .components[0].id
                        },
                        [`courses_track.${courseId}.finished_lessons`]: finished_lessons,
                        
                    }).catch(err=>{alert(err);console.log(err)})
                    
                    setUserInfo(prevState => ({
                        ...prevState,
                        courses_track: {
                            ...prevState.courses_track,
                            [courseId]: {
                                ...prevState.courses_track[courseId],
                                pointer:{
                                    lesson: orderedLessons[currentLessonIndex+1].id,
                                    component: fullContent[orderedLessons[currentLessonIndex+1].id]
                                    .components[0].id
                                }
                            }
                        }
                    }))

                    setCurrentPosition({
                        lesson: orderedLessons[currentLessonIndex+1].id,
                        component: fullContent[orderedLessons[currentLessonIndex+1].id]
                        .components[0].id
                    })

                }
            } else {
                //Course is over here
                setContentError("Kurs tugadi. Etiboringiz uchun raxmat!")
            }
        }
    }

    const handleBackNavigation = () => {
        if(contentError){
            setContentError(null)
        } else {
            let currentLessonIndex
            const currentLessonComponents = fullContent[currentPosition.lesson].components
            let currentComponentIndex
            
            let indexLesson = 0
            let indexComponent = 0
            
            orderedLessons.forEach(lesson=>{
                if(lesson.id === currentPosition.lesson){
                    currentLessonIndex = indexLesson
                }
                indexLesson = indexLesson + 1
            })
            currentLessonComponents.forEach(component=>{
                if(component.id === currentPosition.component){
                    currentComponentIndex = indexComponent
                }
                indexComponent = indexComponent + 1
            })
            
            if(currentComponentIndex > 0){
                //If there is previous component of the course HERE YOU ARE
                db.collection("users").doc(currentUser.uid).
                update({
                    [`courses_track.${courseId}.pointer.component`]:currentLessonComponents[currentComponentIndex - 1].id
                }).catch(err=>{alert(err);console.log(err)})
                
                setUserInfo(prevState => ({
                    ...prevState,
                    courses_track: {
                        ...prevState.courses_track,
                        [courseId]: {
                            ...prevState.courses_track[courseId],
                            pointer:{
                                ...prevState.courses_track[courseId].pointer,
                                component: currentLessonComponents[currentComponentIndex - 1].id
                            }
                        }
                    }
                }))
                
                setCurrentPosition(prevState => ({
                    ...prevState,
                    component: currentLessonComponents[currentComponentIndex - 1].id
                })
                )
            } else {
                // There is no previous component , Checking the previous lesson
                if(currentLessonIndex > 0){
                    //Previous lesson has the component to display HERE YOU ARE
                    db.collection("users").doc(currentUser.uid).
                    update({
                        [`courses_track.${courseId}.pointer`]: {
                            lesson: orderedLessons[currentLessonIndex-1].id,
                            component: fullContent[orderedLessons[currentLessonIndex-1].id]
                            .components[fullContent[orderedLessons[currentLessonIndex-1].id].components.length-1].id
                        }
                        
                    }).catch(err=>{alert(err);console.log(err)})
                    
                    setUserInfo(prevState => ({
                        ...prevState,
                        courses_track: {
                            ...prevState.courses_track,
                            [courseId]: {
                                ...prevState.courses_track[courseId],
                                pointer:{
                                    lesson: orderedLessons[currentLessonIndex-1].id,
                                    component: fullContent[orderedLessons[currentLessonIndex-1].id]
                                    .components[fullContent[orderedLessons[currentLessonIndex-1].id].components.length-1].id
                                }
                            }
                        }
                    }))

                    setCurrentPosition({
                        lesson: orderedLessons[currentLessonIndex-1].id,
                        component: fullContent[orderedLessons[currentLessonIndex-1].id]
                        .components[fullContent[orderedLessons[currentLessonIndex-1].id].components.length-1].id
                    })

                } else {
                    //Course is at the begining
                    return
                }
            }
            }
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {

            //Fetching all the components of all the lessons
            let temporary_full_content
            await db.collectionGroup("articles")
            .where("course_id", "==", courseId).orderBy("order", "asc")
            .get()
            .then(snapshot => {
                let lessons = {}
                snapshot.forEach(article => {
                    if(!lessons[`${article.data().lesson_info[0]}`]){
                        lessons[`${article.data().lesson_info[0]}`] = {
                            id: article.data().lesson_info[0],
                            number: parseInt(article.data().lesson_info[1]),
                            title: article.data().lesson_info[2],
                            components: [{...article.data(), id: article.id}] 
                        } 
                    } else {
                        lessons = {
                            ...lessons,
                            [`${article.data().lesson_info[0]}`]: {
                                ...lessons[`${article.data().lesson_info[0]}`],
                                components: [
                                    ...lessons[`${article.data().lesson_info[0]}`].components,
                                    {...article.data(), id: article.id}
                                ]
                            }
                        }
                    }
                })
                temporary_full_content = lessons
                setFullContent(lessons)
                if(temporary_full_content.length === 0){
                    setContentError("Bu darslik materiallari hali joylashtirilmagan")
                }
            }).catch(err => console.log(`Error: ${err}`))
            
            //Fetching lessons in the order
            let temporary_ordered_lessons // Creating temporary fiels to make the data accessable with in useeffect
            await db.collection("courses").doc(courseId)
            .collection("lessons").orderBy("number", "asc").get()
            .then(snapshot => {
                let lessons = []
                snapshot.forEach((lesson) => {
                    lessons.push({...lesson.data(), id: lesson.id})
                })
                temporary_ordered_lessons = lessons
                setOrderedLessons(lessons)
            }).catch(err => console.lof(`Error: ${err}`))
            let temporary_user_info = {} // Creating temporary fiels to make the data accessable with in useeffect
            if(!!userInfo){
                await db.collection("users").doc(currentUser.uid).get()
                .then(doc => {
                    setUserInfo({...doc.data()})
                    temporary_user_info = {...doc.data()}
                }).catch(err=>`Error: ${err}`)
            }

            
            if(temporary_user_info.courses_track[courseId].pointer.component){
                setCurrentPosition(temporary_user_info.courses_track[courseId].pointer)
            } else {
                let new_pointer = {
                    lesson: temporary_ordered_lessons[0].id,
                    component: temporary_full_content[temporary_ordered_lessons[0].id].components[0].id
                }
                await db.collection("users").doc(currentUser.uid)
                .update({
                    [`courses_track.${courseId}.pointer`]:{
                        ...new_pointer
                    } 
                }).then(ref => {
                    setUserInfo(prevState => ({
                        ...prevState,
                        courses_track:{
                            ...prevState.courses_track,
                            [courseId]: {
                                ...prevState.courses_track[courseId],
                                pointer:{
                                    ...new_pointer
                                }
                            }
                        }
                    }))
                    setCurrentPosition(new_pointer)
                })
            }
            setLoading(false)
        }
        fetchData()
    }, [courseId])

    return(
        <React.Fragment>
            <Grid className={ classes.root } container>
                {
                    loading ? 
                    <div className={classes.progressContainer}>
                        <LinearProgress />
                    </div>:
                    <React.Fragment>
                        <Hidden smDown>
                            <Grid item xs={3}>
                                <ContentTable
                                    pointer={currentPosition}
                                    orderedLessons={orderedLessons}
                                    fullContent={fullContent}
                                    courseId = {courseId}
                                />
                            </Grid>
                        </Hidden>
                        <Grid direction="column" container item xs={12} sm={9}>
                            <NavButtons handleBackNavigation={handleBackNavigation} handleNextNavigation={handleNextNavigation} /> 
                            {
                                fullContent[currentPosition.lesson]?
                                !contentError ?
                                <MainArea
                                    handleNextNavigation = {handleNextNavigation}
                                    components = {fullContent[currentPosition.lesson].components}
                                    courseId = {courseId}
                                    pointer={currentPosition}
                                />:
                                <Grid container item xs={8} justify="center" align="center">
                                    <Typography variant="h4" color="secondary">
                                        {contentError}
                                    </Typography>
                                </Grid>:
                                <Typography>
                                    Dars materiallari hali yuklanmagan
                                </Typography>
                            }
                            <NavButtons handleBackNavigation={handleBackNavigation} handleNextNavigation={handleNextNavigation} />
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    )
}

export default CourseContent
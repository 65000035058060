import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import authorImage from "../assets/author.jpg"

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop:10,
        flexGrow:1
    },
    logoContainer:{
        justifyContent:"center"
    },
    footerButton: {
        width: 150
    },
    blueContent: {
        color: theme.palette.custom
    }
}))

const Footer = () => {
    const classes = useStyles()
    return(
        <React.Fragment>
            <Divider variant="middle"/>
            <Grid className={classes.root} container spacing={2} justify="space-around">
                <Grid item xs={12} md={4} style={{padding:30}}>
                    <Grid container xs={12}>
                        <Typography  variant="h4" color="secondary">
                            Bog'lanish uchun
                        </Typography>
                    </Grid>
                    <Typography style={{marginTop:20}} variant="h5" color="secondary">
                        E-mail:
                    </Typography>
                    <Typography variant="h6">
                        yulduzrahmatovarustamovna@gmail.com
                    </Typography>
                    <Typography style={{marginTop:20}} variant="h6" color="secondary">
                        Telegram:
                    </Typography>
                    <Typography variant="h6">
                        https://t.me/skaneruz
                    </Typography>
                    <hr/>
                    <Grid container xs={12}>
                        <Typography  variant="h5" color="secondary">
                            Foydalanilgan manbalar:
                        </Typography>
                    </Grid>
                    <Typography variant="body1">
                        Coursera:  English for Media Literacy - <a href="https://www.coursera.org/learn/media" target="_blank">havola</a>
                    </Typography>
                    <Typography variant="body1">
                        Coursera:  Making Sense of the News: News Literacy Lessons for Digital Citizens - <a href="https://www.coursera.org/learn/news-literacy" target="_blank">havola</a>
                    </Typography>
                    <Typography variant="body1">
                        EdX: Arizona State University MCO425x MediaLIT - <a href="https://learning.edx.org/course/course-v1:ASUx+MCO425x+2T2015/home" target="_blank">havola</a>
                    </Typography>
                    <Typography variant="body1">
                        Media sabak: Axborot-savodxonligini o’rganish - <a href="https://mediasabak.org/uz/education/blocks/" target="_blank">havola</a>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4} container style={{padding:30}}>
                    <Grid container alignContent="center" justify="center" item xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="secondary">
                                Loyiha muallifi
                            </Typography>
                            <hr/>   
                        </Grid>
                        <Grid item xs={12}>
                            <Typography alig="center" justify="center" variant="h6">
                                Yulduz Rahmatova
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" justify="center" item xs={4}>
                        <img style={{width:100, borderRadius:60}} src={authorImage}/>
                    </Grid>
                    <Grid style={{marginTop:10}} container item xs={12}>
                        <Typography align="justify" variant="body1">
                            Yulduz Rahmatova O‘zbekiston davlat jahon tillari universitetining Xalqaro jurnalistika fakultetida bakalavr darajasini oladi. Undan so‘ng Jahon iqtisodiyoti va diplomatiya universitetida magistraturada  tahsil olgan. Hozirda FA Davlat va huquq institutida tayanch doktaranturada o‘qimoqda. Muallif talabalik yillarida Xalq taʼlimi sohasida AKTni rivojlantirish markazi tomonidan tashkil etilgan turli xil media loyihalarda ishtirok etgan. Faoliyati davomida yoshlarda axborot savodxonligini shakllantirish bugungi kunning hali yetarlicha eʼtibor berilmagan dolzarb muammosi ekanligini anglaydi va o‘z izlanishlarini shu yo‘nalishda olib borishga qaror qiladi. “Jamoatchilikda axborotga munosabat va informatsion immunitetni shakllantirishda media savodxonlikning o‘rinini oshirish” media loyihasini ishlab chiqadi va Toshkent Amerika Markazi homiyligida  ushbu platformani yaratdi.
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} md={4} container style={{padding:30}}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="secondary">
                            Loyiha haqida 
                        </Typography>
                        <Typography align="justify" variant="subtitle1">
                            Ushbu media taʼlim platformasi O‘zbekiston davlat jahon tillari universiteti Xalqaro jurnalistika fakulteti talabasi Yulduz Rahmatovaning  bitiruv malakaviy ishi sifatida ishlab chiqilgan. Platformada axborot savodxonligiga oid 3 ta kurs mavjud bo‘lib, har bir kurs video darslar, test topshiriqlari va mavzu bo‘yicha qo‘shimcha adabiyotlardan iborat. “Media bo‘yicha ilk saboqlar” kursi axborotni to`g`ri baholash bo‘yicha boshlang‘ich bilimlarni beradigan animatsiya shaklidagi video darslardan iborat. “Axborotni to`g`ri baholash” kurs maʼlumotlardagi noxolisliklarni aniqlash, yashirin reklamalarni payqash, axborotni tahlil qilish va   tekshirishning turli usullari  haqida yozib olingan video darslardan iborat. Platformani rivojlantirish va video darslar tayyorlash Toshkent Amerika markazi homiyligida amalga oshirildi.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                       
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Footer
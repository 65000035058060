import React, { useState, useContext, useEffect } from "react";
import  * as firebase from 'firebase';
import { withRouter, useHistory } from 'react-router-dom';
import {isGoogleUserEqual} from "./isGoogleUser";
import app from "../../firebase";
const db = app.firestore()

const Login = ({from}) => {
	const [isNewUser, setIsNewUser] = useState(false)
	const [error, setError] = useState("");
	const history = useHistory()

	const onSignIn = (googleUser) => {
		// We need to register an Observer on Firebase Auth to make sure auth is initialized.
		const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
			unsubscribe();
			// Check if we are already signed-in Firebase with the correct user.
			if (!isGoogleUserEqual(googleUser, firebaseUser)) {
				// Build Firebase credential with the Google ID token.
				var credential = firebase.auth.GoogleAuthProvider.credential(
					googleUser.getAuthResponse().id_token,
					googleUser.accessToken
				);
				// Sign in with credential from the Google user.
				firebase
				.auth()
				.signInWithCredential(credential)
				.then(result => {
				if (result.additionalUserInfo.isNewUser) {
					setIsNewUser(true);
					db.collection("users")
					.doc(result.user.uid)
					.set({
						gmail: result.user.email,
						profile_picture: result.additionalUserInfo.profile.picture,
						locale: result.additionalUserInfo.profile.locale,
						first_name: result.additionalUserInfo.profile.given_name,
						last_name: result.additionalUserInfo.profile.family_name,
						created_at: Date.now(),
					})
					.then(snapshot => {
						history.replace(from)
					})
					.catch(error => { setError(error); console.log(error)});
				} else {
					db.collection("users")
					.doc(result.user.uid)
					.update({
						last_logged_in: Date.now()
					})
					.then(snapshot => {
						history.replace(from)
					})
					.catch(error => {console.log("Error: ", error); setError(error)});
				}
				})
				.catch(function(error) {
					setError(error)
					// Handle Errors here.
					var errorCode = error.code;
					var errorMessage = error.message;
					// The email of the user's account used.
					var email = error.email;
					// ThesetError firebase.auth.AuthCredential type that was used.
					var credential = error.credential;
					// ...
				});
			} else {
				history.replace(from)
			}
		});
	}

	useEffect(() => {
		window.gapi.signin2.render('g-signin2', {
		  'scope': 'https://www.googleapis.com/auth/plus.login',
		  'width': 200,
		  'height': 50,
		  'longtitle': true,
		  'theme': 'dark',
		  'onsuccess': onSignIn
		})
	})
    return(
    	<div>
    		<div id="g-signin2"></div>
    	</div>
    )
   
}
export default withRouter(Login)
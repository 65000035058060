import React, {useEffect, useState} from "react";
import {
    Grid,
    Typography,
    Button,
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReactPlayer from 'react-player'
import Divider from "@material-ui/core/Divider";
import TestDisplay from "./TestDisplay";

const useStyles = makeStyles((theme)=>({
    root:{
        // marginTop: 25
    },
    contentArea:{
        margin: 20
    }
}))
const MainArea = ({ components, courseId, pointer, handleNextNavigation}) => {
    const classes = useStyles()
    const [type, setType] = useState("")
    const [component, setComponent] = useState("")
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        setLoading(true)
        components.forEach(element => {
            if( element.id === pointer.component ){
                setComponent(element)
                setType(element.type)
            }
        })
        setLoading(false)
    },[pointer, components, courseId])

    return(
        <React.Fragment>
            <Grid container item className={classes.contentArea}>
                {
                    !loading ?
                    type === "v"?
                    <React.Fragment>
                        <Grid style={{marginRight: 30}} container item xs={12}>
                            <Grid item xs={12}>
                                <Typography color="secondary" align="left" variant="h5">
                                    {
                                        component.title?
                                        `${component.order}-Dars | ${component.title}`:
                                        `${component.order}-Dars | ${component.lesson_info[2]}`
                                    }
                                </Typography>
                            </Grid>
                            <Grid style={{height:500}}  item xs={12}>
                                <ReactPlayer 
                                    width='100%'
                                    height='100%'
                                    url={component.data.url} 
                                    playing={true} 
                                />
                            </Grid>
                            <Grid style={{marginTop:20}} item xs={12}>
                                <Typography style={{fontSize:20}} align="justify" variant="subtitle1">
                                    {component.data.description}
                                </Typography>
                            </Grid>
                            <Grid style={{marginTop:20}} item xs={12}>
                                {   
                                    component.data.info_urls ?
                                    <div>
                                        <Typography variant="h6" color="primary">
                                            Qo`shimcha ma`lumot uchun quyidagi havolalarga tashrif buyiring:
                                        </Typography>
                                        <ul style={{fontSize: 15}}>
                                            {
                                                component.data.info_urls.map((item)=>{
                                                    return(<li key={item}><a target="blank" href={`${item}`}>{item}</a></li>)
                                                })
                                            }
                                        </ul>
                                    </div>:
                                    <div></div>
                                }
                            </Grid>
                        </Grid>
                    </React.Fragment>:
                    <React.Fragment>
                        <Grid container item xs={12}>
                            <TestDisplay courseId={courseId} component={component} />
                        </Grid>
                    </React.Fragment>:
                    <Typography>
                        Loading...
                    </Typography>       
                }
                {/* Main Course Content is going to be here */}
            </Grid>
        </React.Fragment>
    )
}

export default MainArea
import React, { useEffect, useCallback, useContext, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import app from "../firebase";
import { AuthContext } from "../components/auth/AuthProvider";
import LoginGoogle from "../components/auth/LoginGoogle";

//Material UI imports as follows:
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progressContainer: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
  },
},
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const {from}= location.state || { from: { pathname: "/" } }
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  // const [email, setEmail] = useState()
  // const [password, setPassword] = useState()

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true)
      const { email, password } = event.target.elements;
      event.preventDefault();
      await app
      .auth()
      .signInWithEmailAndPassword(email.value, password.value).then(snapshot=>{
        setLoading(false)
        history.replace(from);
      }).catch(error => {
        setLoading(false)
        let errorCode = error.code
        let errorMessage = error.message
        if(errorCode === 'auth/wrong-password'){
          alert("Wrong password")
        } else if(errorCode==='auth/invalid-email'){
          alert("The invalid email was provided")
        } else if(errorCode==="auth/user-disabled"){
          alert("This user was disabled")
        }else if(errorCode==="auth/user-not-found"){
          alert("This user was not found")
        } else {
          alert(errorMessage)
        }
        console.log(`Error: ${error}`);
      });
    },
    [history, from]
  );

  if (currentUser) {
    return <Redirect to={from} />;
  }

  return (
    <Container style={{height:"90vh"}} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography align="center" component="h1" variant="h4" color="primary">
          Google orqali kiring
        </Typography>
        {
          loading ? 
          <div className={classes.progressContainer}>
              <LinearProgress />
          </div>:
          <div></div>
        }
        <hr/>
        <LoginGoogle onClick={()=>setLoading(true)} from={from}/>
        <Typography align="center" component="h1" variant="h6" color="secondary">
          Yoki sizga taqdim etilgan login va parol orqali
        </Typography>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Login"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parol"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading ? <CircularProgress /> :"Kirish"}
          </Button>
        </form>
      </div>
    </Container>
  );
}
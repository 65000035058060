import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const About = props => {
    return(
        <Grid container item xs={12}>
            <Grid xs={2}></Grid>
            <Grid xs={8}>
                <Grid xs={12} item>
                    <Typography align="center" variant="h4" color="secondary">
                        Loyiha haqida
                    </Typography>
                </Grid>
                <Typography align="justify" variant="h6">
                    <span style={{opacity:0}}>sentence</span>“Jamoatchilikda axborotga munosabat va informatsion immunitetni shakllantirishda media savodxonlikning o‘rinini oshirish” .  Ushbu loyiha O‘zbekiston davlat jahon tillari universiteti Xalqaro jurnalistika fakulteti talabasining Bitiruv malakaviy ishi sifatida ishlab chiqilgan.  Loyihaning maqsadi  insonlarda axborot savodxonligini shakllantirish  va turli xil axborot hujumlariga qarshi immunitetni paydo qilishdan iborat. Foydalanuvchilarning yoshi va bilim darajasi inobatga olingan holda loyiha uchta kursdan tashkil topgan.  
                </Typography>
                <Typography align="justify" variant="h6">
                    <span style={{opacity:0}}>sentence</span>Birinchi kurs  “Mediaga  kirish” deb nomlanib axborot  savodxonligi  bo‘yicha boshlang‘ich bilimlarni beradigan  o‘n beshta video darsdan iborat. Namuna sifatida uchta video dars tasvirga olingan. Foydalanuvchi  keyingi qisimda  “Axborotni baholash” kursida axborotning tub mohiyatini aniqlash  va to‘g‘ri baho berish qobiliyatini shakllantirishga yordam beradigan bilimlarni oladi.  Ushbu kurs ham o‘n beshta darsdan iborat bo‘lib, namuna sifatida uchta dars tasvirga olingan.  Keyingi kurs axborot va matnlar bilan ishlaydigan mutaxassislar uchun  mo‘ljallangan. Kurs  “Jumlalar ortidagi yashirin ma’nolar” deb nomlanadi.   
                    Mavzular test topshiriqlari va qo'shimcha adbiyotlar bilan mustahkamlanadi.
                </Typography>
            </Grid>
            <Grid xs={2}></Grid>
        </Grid>
    )
}

export default About
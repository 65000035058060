import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CourseItem from "../components/home/CourseItem";
import Hidden from "@material-ui/core/Hidden"
import LinearProgress from '@material-ui/core/LinearProgress';

import app from "../firebase";

const db = app.firestore()
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow:1,
        height:"90vh"
    },
    coursesContainer:{
        marginLeft:20,
        marginRight: 20,
        marginBottom:40
    },
    progressContainer: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
}))

const HomePage = () => {
    const [loading, setLoading] = useState(false)
    const [coursesList, setCoursesList] = useState([])
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            await db.collection("courses").orderBy("order", "asc").get()
            .then(snapshot => {
                let courses = []
                snapshot.forEach(course => {
                    courses.push({...course.data(), id: course.id})
                })
                setCoursesList(courses)
                setLoading(false)
            }).catch(err=>console.log(`Error: ${err}`))
        }
        fetchData()
    }, [])
    const classes = useStyles()
    return(
        <div>
            <Grid className = {classes.root} container>
                <Hidden smDown>
                    <Grid container style={{height:10}} item xs={12}></Grid>
                </Hidden>
                {
                    loading ? 
                    <div className={classes.progressContainer}>
                        <LinearProgress />
                    </div>:
                    <div></div>
                }
                <Grid className = {classes.coursesContainer} xs={12} item container justify="center" spacing={3}>
                    {
                        loading ?
                        <div></div>:
                        coursesList.length > 0 ?
                        coursesList.map( course => {
                            return(
                                <Grid key={course.id} item xs={12} md={4}>
                                    <CourseItem data={course}/>
                                </Grid>
                            ) 
                        }):
                        <div></div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default HomePage;
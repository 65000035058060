import React from "react"
import {Grid, Button, Typography} from "@material-ui/core"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const NavButtons = ({handleNextNavigation, handleBackNavigation}) => {
    return(
        <Grid container item>
            <Grid xs={2} justify="flex-start">
                <Button 
                    style={{marginLeft:20, marginRight: 20}} 
                    color="secondary" 
                    variant="contained"
                    onClick={handleBackNavigation}
                >
                    <NavigateBeforeIcon/>
                    <Typography>
                        Orqaga
                    </Typography>
                </Button>
            </Grid>
            <Grid xs={8}></Grid>
            <Grid xs={2} justify="flex-end">
                <Button 
                    style={{marginLeft:20, marginRight: 20}} 
                    color="secondary" 
                    variant="contained"
                    onClick={handleNextNavigation}
                >
                    <Typography>
                        Oldinga
                    </Typography>
                    <NavigateNextIcon/>
                </Button>
            </Grid>
        </Grid>
    )
}

export default NavButtons
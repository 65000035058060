import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"
export default function TestItem({error, question, setCorrectValues, indexGlobal}) {
    const [checkedValues, setCheckedValues] = useState(new Array(question.options.length).fill(false));
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState()
    
    const handleChange = (event, index) => {
        event.persist()
        let new_array = new Array(question.options.length).fill(false)
        new_array[index] = event.target.checked
        setCheckedValues(new_array)
        setCorrectValues(prevState => {
            let tempo = [...prevState]
            tempo[indexGlobal] = correctAnswerIndex === new_array.indexOf(true)
            return(tempo)

        })
    };

    useEffect(() => {
        question.options.forEach((element, index) => {
            if(element.correct){
                setCorrectAnswerIndex(index)
            }
        });
    },[])

    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} justify="center" alignItems="center">
                <Grid container item xs={12} justify="flex-start" alignItems="center">
                    <Typography variant="h6">
                        {question.question}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="felx-start" alignItems="center">
                    {
                        error ?
                        <Typography color="primary" variant="subtitle1">
                            Xato javob, qayta urinib ko`ring
                        </Typography> :
                        <div></div>
                    }
                </Grid>
            </Grid>
            <Grid container item justify="center" xs={12}> 
                {
                    question.options.map((option, index) => {
                        return(
                            <Grid container item xs={12} justify="flex-start" alignItems="center">
                                <Checkbox
                                    key={option.option}
                                    checked={checkedValues[index]}
                                    onChange={(event) => handleChange(event, index)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography>{option.option}</Typography>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}
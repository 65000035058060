import React, { useState, useContext } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TestItem from "./TestItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"
import {AuthContext} from "../auth/AuthProvider";
import app from "../../firebase";

const db = app.firestore()
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
}));

const TestDisplay = ({ component, courseId }) => {
    const classes = useStyles();
    const [correctValues, setCorrectValues] = useState(new Array(component.data.questions.length).fill(false))
    const [errors, setErrors] = useState(new Array(component.data.questions.length).fill(false))
    const [allCorrect, setAllCorrect] = useState(false)
    const {currentUser, userInfo, setUserInfo} = useContext(AuthContext)
    
    const combinerIfDoesntInclude = (list, item) => {
        let contains = list.includes(item)
        if(contains){
            return [...list]
        } else {
            return [...list, item]
        }
        
    }
    
    const handleCheck = () => {
        let tempo_errors = []
        correctValues.forEach((item, index) => {
            tempo_errors.push(!item)
        })
        setErrors(tempo_errors)
        if(correctValues.indexOf(false) === -1){
            const finished_components = combinerIfDoesntInclude(
                userInfo.courses_track[courseId].finished_components,
                component.id
            )

            db.collection("users").doc(currentUser.uid).
            update({
                [`courses_track.${courseId}.finished_components`]:finished_components,
            }).catch(err=>{alert(err);console.log(err)})
            
            setUserInfo(prevState => ({
                ...prevState,
                courses_track: {
                    ...prevState.courses_track,
                    [courseId]: {
                        ...prevState.courses_track[courseId],
                        finished_components:finished_components,
                    }
                }
            }))
            setAllCorrect(true)
        } else {
            setAllCorrect(false)
        }
    }
    
    return(
        <React.Fragment>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography color="secondary" align="left" variant="h5">
                        {
                            `${component.order}-Dars | ${component.lesson_info[2]} | Test`
                        }
                    </Typography>
                </Grid>
                <Grid xs={12} item >
                    <Typography variant="h6">{component.title ? component.title : ""}</Typography>
                </Grid>
                <Grid container item xs={12}>
                    {
                        component.data.questions.map((question, index) => {
                            return(
                                <TestItem 
                                    error={errors[index]}
                                    key={question.question} 
                                    question={question}
                                    indexGlobal = {index}
                                    setCorrectValues = {setCorrectValues}
                                />
                            )
                        })
                    }
                </Grid>
                <Grid container item xs={12} justify="center">
                    <Button onClick={handleCheck} variant="contained" color="primary" className={classes.button}>
                        Tekshirish
                    </Button>
                </Grid>
                <Grid container item xs={12} justify="center">
                    {
                        allCorrect ?
                        <Typography variant="h6" color="secondary">
                            Hammasi to`g`ri
                        </Typography> :
                        <div></div>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default TestDisplay